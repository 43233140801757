import type { Notification } from '#ui/types';

const useNotifications = () => {
  const toast = useToast();

  const showNotification = (config: Partial<Notification>) => {
    toast.add({
      ...config,
    });
  };

  const showSuccessNotification = (config: Partial<Notification>) => {
    showNotification({
      ...config,
      ui: { background: 'bg-[#3DEEB2]', ...config.ui },
    });
  };

  const showErrorNotification = (config: Partial<Notification>) => {
    showNotification({
      ...config,
      ui: { background: 'bg-[#FF7177]', ...config.ui },
    });
  };

  return {
    removeNotification: toast.remove,
    showNotification,
    showSuccessNotification,
    showErrorNotification,
  };
};

export default useNotifications;
